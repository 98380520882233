<template>
  <v-container>
    <v-row multi-line>
      <v-col cols="12 ">
        <h3>Contratación</h3>
      </v-col>
      <v-col cols="10" offset="1">
        <stepper color="#0077c8" :selectedStep="step" :items="stepperItems" />
      </v-col>
    </v-row>

    <div v-if="!error && !loading" class="my-6">
      <!-- PASO 1 ASEGURADO -->
      <PersonStep v-if="step == 1" v-bind="componentData" ref="stepComponent" />

      <!-- PASO 2 BIEN ASEGURADO -->
      <GoodStep
        v-else-if="step == 2"
        v-bind="componentData"
        ref="stepComponent"
      />

      <!-- PASO 3 REQUISITOS -->
      <Requirements
        ref="stepComponent"
        v-else-if="step == 3"
        v-bind="componentData"
        :newCar="getProposalData.cotizacion.nuevo"
        :needInspection="advanceInfo.aplica_inspeccion"
        :needDocument="advanceInfo.requiere_documento_venta"
        :inspeccionesList="advanceInfo.inspecciones"
      />

      <!-- PASO 4 MEDIOS DE PAGO -->
      <PayerStep
        v-else-if="step == 4"
        v-bind="componentData"
        ref="stepComponent"
      />

      <!-- PASO 5 FIRMA -->
      <FirmaDocumentos
        v-if="step === 5"
        :UserInfo="getProposalData"
        :signature-type="advanceInfo.tipo_firma"
        :channel="channel"
      />
    </div>
    <!-- Ventana de carga -->
    <v-row justify="center" v-else-if="loading">
      <v-col cols="12" md="8" class="pt-10">
        <v-skeleton-loader
          type="article, list-item-three-line, list-item-two-line, list-item-three-line, list-item-two-line,  list-item-two-line, actions"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <div class="text-center" v-if="step > 0 && step < 3 && !loading">
      <v-btn
        color="primary"
        class="mx-1 text-none px-8"
        rounded
        outlined
        :disabled="stepLoading"
        v-if="step !== 1"
        @click="setBack()"
        ><v-icon left>mdi-arrow-left</v-icon>Volver</v-btn
      >
      <v-btn
        color="primary"
        rounded
        class="mx-1 px-8 text-none"
        :loading="stepLoading"
        @click="setNext()"
        @keyup.enter="setNext()"
        >Continuar <v-icon right>mdi-arrow-right</v-icon></v-btn
      >
      <!-- SNACKBAR ESPECIFICO PARA CASOS DE ERROR EN PASOS -->
      <v-snackbar
        v-model="snackbarActivated"
        :timeout="2000"
        color="error"
        top
        right
      >
        <v-icon>mdi-close-circle-outline</v-icon>
        {{ errorMsg }}

        <template v-slot:action="{ attrs }">
          <v-btn
            class="mx-2"
            fab
            x-small
            text
            v-bind="attrs"
            @click="closeSnackbar()"
            name="boton_cerrar_snackBar"
          >
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </div>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import stepper from "@/components/Stepper/Stepper";
import PersonStep from "@/components/User-Components/Contract/PersonStep.vue";
import GoodStep from "@/components/User-Components/Contract/GoodStep";
import PayerStep from "@/components/User-Components/Contract/PayerStep.vue";
import FirmaDocumentos from "@/components/User-Components/Contract/FirmaDocumentos.vue";
import Requirements from "@/components/User-Components/Contract/RequirementStep.vue";
export default {
  name: "ContractPage",
  props: {
    id: {
      type: [String, Number],
      default: "",
      description: "Id de la propuesta",
    },
  },
  components: {
    stepper,
    PersonStep,
    GoodStep,
    PayerStep,
    FirmaDocumentos,
    Requirements,
  },
  data() {
    return {
      loading: false,
      stepLoading: false,
      error: false,
      noInspect: true,
      noReq: false,
      noGoodies: false,
      noReqBranch: true,
      snackbarActivated: false,
      errorMsg: "",
      stepperItems: [
        {
          id: 1,
          code: "person",
          label: "Asegurado",
          icon: "mdi-tooltip-account",
          active: true,
        },
        {
          id: 2,
          code: "goodies",
          label: "Bien asegurado",
          icon: "mdi-tooltip-cellphone",
          active: true,
        },
        {
          id: 3,
          code: "requirements",
          label: "Otros Requisitos",
          icon: "mdi-tooltip-text",
          active: false,
        },
        {
          id: 4,
          code: "prime",
          label: "Medio de pago",
          icon: "mdi-tooltip-check",
          active: true,
        },
        {
          id: 5,
          code: "signature",
          label: "Firma",
          icon: "mdi-tooltip-edit",
          active: true,
        },
      ],
    };
  },
  mounted() {
    this.getLocations();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  },
  async created() {
    const { cotizacion, bien_asegurado, otros_requisitos } = await this.setup();
    // ====== VERIFICACION DE LOS PASOS DEL CONTRATO ======= //
    let reqStep;
    // Activar paso Inspeccion en el caso de que sea un auto usado y aplique inspeccion
    if ((!cotizacion?.nuevo || false) && this.advanceInfo?.aplica_inspeccion) {
      this.noInspect = false;
    }
    // Desactivar paso Requisitos en caso de que no aplique inspeccion y no requiera documento
    if (this.advanceInfo && !this.advanceInfo.requiere_documento_venta) {
      this.noReq = true;
    }
    // Desactivar/Activar otros requisitos por ramo
    if (typeof otros_requisitos === "boolean") {
      this.noReqBranch = !otros_requisitos;
    }
    // Validar si se requiere al menos inspeccion o requisitos adiconales
    if (!this.noReqBranch && (!this.noReq || !this.noInspect)) {
      reqStep = this.stepperItems.find((e) => e.code == "requirements");
      if (reqStep) {
        reqStep.active = true;
      }
    }
    // Desactivar/activar paso bien asegurado
    const goodies = bien_asegurado?.requerido;
    if (typeof goodies == "boolean") {
      this.noGoodies = !goodies;
      reqStep = this.stepperItems.find((e) => e.code == "goodies");
      if (reqStep) {
        reqStep.active = goodies;
      }
    }

    // Informacion adicional para los medios de pago PAC Y PAT
    this.getPAC({ numero_propuesta: this.id });
    this.getPAT({ numero_propuesta: this.id });
  },
  computed: {
    ...mapGetters("Users", ["getUserInfo"]),
    ...mapGetters("Proposal", ["getProposalData", "getStep"]),

    // Datos generales a pasarle a los componentes de Pasos
    componentData() {
      return {
        next: this.next,
        back: this.back,
        loading: this.stepLoading,
        data: this.getProposalData,
        proposal: this.id,
        channel: this.channel.description,
        client: this.getUserInfo,
      };
    },

    // Paso actual
    step() {
      const step = this.$route.query?.paso || 1;
      return typeof step === "string" ? parseInt(step) : step;
    },

    // Condiciones del paso
    stepCondition() {
      return {
        1: false,
        2: this.noGoodies ?? false,
        3: (this.noReqBranch || (this.noReq && this.noInspect)) ?? false,
        4: false,
        5: false,
      };
    },

    // Datos del canal
    advanceInfo() {
      return this.$store.getters.getAdvanceInfo;
    },
    channel() {
      return this.$store.getters.getChannelSelected;
    },
  },
  methods: {
    ...mapActions("Payment", ["getPAT", "getPAC"]),
    ...mapActions("Proposal", [
      "getLocations",
      "setProposalStep",
      "getProposal",
    ]),

    setup() {
      return new Promise((resolve) => {
        this.loading = true;
        this.getProposal(this.id)
          .then((response) => {
            if (!response?.cotizacion) {
              throw new Error(
                "No se ha encontrado la cotizacion de esta propuesta"
              );
            }
            this.setStep();
            resolve(response);
          })
          .catch((e) => {
            this.error = true;
            this.$store.commit("setSnackbar", {
              active: true,
              text: e.toString(),
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },

    // STEPS METHODS
    setStep() {
      if (!(this.step == this.getStep)) {
        this.$router.replace({
          query: { paso: this.getStep },
        });
      }
    },
    setNext() {
      this.stepLoading = true;
      const step = this.$refs.stepComponent;
      if (step) {
        step
          .goNext()
          .then(async (response) => {
            await this.next();
            if (response) {
              this.$store.commit("setSnackbar", {
                active: true,
                text: response,
                top: true,
                right: true,
                color: "success",
              });
            }
          })
          .catch((error) => {
            if (error) {
              this.snackbarActivated = true;
              this.errorMsg = error;
            }
          })
          .finally(() => {
            this.stepLoading = false;
          });
      }
    },
    next() {
      return new Promise((resolve) => {
        let newStep = this.step + 1;
        switch (this.step) {
          case 1: {
            if (this.stepCondition[2]) {
              if (this.stepCondition[3]) {
                newStep = 4;
              } else {
                newStep = 3;
              }
            }
            break;
          }
          case 2: {
            if (this.stepCondition[3]) {
              newStep = 4;
            }
            break;
          }
          case 3:
            break;
          case 4:
            break;

          default: {
            newStep = 1;
            break;
          }
        }

        this.setProposalStep({
          id: this.id ?? "",
          step: newStep,
        }).then(() => {
          this.setStep();
          resolve();
        });
      });
    },
    setBack() {
      this.back();
    },
    back() {
      let newStep = this.step - 1,
        name = "Contract",
        params = {},
        query = {};

      params.id = this.id;
      switch (this.step) {
        case 2:
          break;
        case 3: {
          if (this.stepCondition[2]) {
            newStep = 1;
          }
          break;
        }
        case 4: {
          if (this.stepCondition[3]) {
            if (this.stepCondition[2]) {
              newStep = 1;
            } else {
              newStep = 2;
            }
          }
          break;
        }
        case 5:
          break;

        default: {
          const nroCotizacion =
            this.getProposalData?.cotizacion?.numero_cotizacion;
          if (nroCotizacion) {
            name = "quotationOffers";
            params = { id: nroCotizacion };
          } else {
            name = "dashboard";
          }
          break;
        }
      }
      query.paso = newStep;

      this.$router.push({
        name,
        params,
        query,
      });
    },
  },
};
</script>
