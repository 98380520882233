<template>
  <v-form ref="docFormPeru">
    <v-container>
      <v-row>
        <v-col cols="12">
          <!-- Tipo de Documento -->
          <!-- {{ tipo_documento }} -->
          <LabelComponent text="Tipo de Documento" />
          <v-select
            :items="tipo_documentos"
            v-model="tipo_documento"
            placeholder="Seleccione"
            outlined
            dense
            item-value="codigo"
            clearable
            item-text="descripcion"
            append-icon="mdi-chevron-down"
            name="doc_adicional"
            :rules="[rules.required]"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <!-- Fecha de documento -->
          <LabelComponent
            :text="
              tipo_documento === 'BOL' ? 'Fecha de boleta' : 'Fecha de carta'
            "
          />
          <v-menu
            ref="menu"
            v-model="dateMenu"
            transition="scale-transition"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormattedAd"
                readonly
                v-bind="attrs"
                v-on="on"
                clearable
                :rules="[rules.required]"
                outlined
                placeholder="DD/MM/AAAA"
                dense
                name="fecha_doc_adicional"
                append-icon="mdi-calendar-month"
              >
              </v-text-field>
              <!-- :rules="ventaVerde != 'N' ? [] : [rules.hrs48]" -->
            </template>
            <v-date-picker
              v-model="documento.fecha"
              min="1950-01-01"
              name="picker_fecha_adicional"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="6">
          <!-- Numero de boleta -->
          <LabelComponent
            :text="
              tipo_documento === 'BOL' ? 'Numero de boleta' : 'Numero de carta'
            "
          />
          <v-text-field
            outlined
            dense
            type="text"
            clearable
            :rules="[rules.required]"
            v-model="documento.numero"
            name="numero_factura"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <!-- Docum ento Adjunto -->
          <LabelComponent
            :text="
              tipo_documento === 'BOL'
                ? 'Adjuntar boleta'
                : 'Adjuntar carta de características'
            "
          />
          <v-file-input
            outlined
            dense
            prepend-icon="mdi-folder-arrow-up"
            @change="handlerSelectFile($event, 'documento')"
            accept="application/pdf"
            name="adj_doc_factura"
            ref="file-input-documento"
          >
          </v-file-input>
        </v-col>
        <v-col cols="12">
          <!-- Docum ento Adjunto -->
          <LabelComponent text="Adjuntar comprobante de GPS" />
          <v-file-input
            outlined
            dense
            prepend-icon="mdi-folder-arrow-up"
            @change="handlerSelectFile($event, 'comprobante_gps')"
            accept="application/pdf"
            name="adj_doc_factura"
            ref="file-input-comprobante_gps"
          >
          </v-file-input>
        </v-col>
      </v-row>
    </v-container>

    <!-- BOTONES DIRECCIONALES -->
    <div class="text-center mt-6">
      <v-btn
        color="primary"
        class="mx-1 text-none"
        outlined
        rounded
        @click="$emit('back')"
        width="140"
        name="boton_volver"
        >Volver</v-btn
      >
      <v-btn
        color="primary"
        class="mx-1 ml-4 text-none"
        rounded
        width="140"
        @click="handlerNext()"
        name="boton_continuar"
        >Continuar</v-btn
      >
    </div>
    <!-- {{ tipo_documento }} -->
    <!-- {{ documento }}
    {{ comprobante_gps }} -->
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LabelComponent from "@/components/Inputs/Label.vue";

export default {
  name: "DocumentPeruForm",
  props: {
    proposalNumber: {
      type: [String, Number],
      default: "",
      description: "Numero de la propuesta",
    },
    newCar: {
      type: Boolean,
      default: false,
      description: "Indicador para definir si el automovil es nuevo",
    },
    needDocument: {
      type: Boolean,
      default: false,
      description:
        "Indicador para definir si la propuesta amerita que el usuario adjunte un documento de factura",
    },
    greenSale: {
      type: Boolean,
      default: false,
    },
  },
  components: { LabelComponent },
  data() {
    return {
      tipo_documentos: [
        {
          id: "1",
          descripcion: "Boleta",
          codigo: "BOL",
        },
        {
          id: "2",
          descripcion: "Carta de características",
          codigo: "CDC",
        },
      ],
      dateMenu: false,

      tipo_documento: "",
      documento: {
        fecha: "",
        numero: "",
        documento: "",
        nombre: "",
        mime: "",
      },
      comprobante_gps: {
        documento: "",
        nombre: "",
        mime: "",
      },
      guia_despacho_nota: null,
      rules: {
        required: (value) => this.greenSale || !!value || "Campo requerido.",
        hrs48: (value) =>
          (value && !this.oldDateAditional) ||
          "Fecha del archivo debe ser menor a 48 horas.",
      },
    };
  },
  computed: {
    ...mapGetters("Proposal", [
      "getDocumentType",
      "getProposalD",
      "getProposalStepData",
    ]),
    computedDateFormattedAd() {
      return this.formatDate(this.documento.fecha);
    },
  },
  methods: {
    ...mapActions("Proposal", [
      "updateRequirements",
      "REQUEST_DOCUMENT_TYPE",
      "getDataProposalStep",
    ]),
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    async handlerSelectFile(file, tipo) {
      if (!file) {
        this[tipo].documento = "";
        this[tipo].mime = "";
        this[tipo].nombre = "";
        return;
      }

      // Vulnerabilidad WhiteHat - Abuse of Functionality #244811
      // evitar cargar archivos maliciosos que no sean pdf
      if (file.type !== "application/pdf") {
        this.$store.commit("setSnackbar", {
          active: true,
          text: "El archivo debe ser de tipo PDF",
          top: true,
          right: true,
          color: "error",
        });

        // limpiar input
        this.$refs["file-input-" + tipo].reset();
        return;
      }

      this[tipo].documento = await this.toBase64(file);
      this[tipo].mime = file.type;
      this[tipo].nombre = file.name;
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.toString().split(",")[1]);
        reader.onerror = (error) => reject(error);
      });
    },
    handlerNext() {
      const form = this.$refs.docFormPeru;
      const isValid = form.validate();

      let formData = { documentos: [] };
      formData.requiere_documento_venta = this.needDocument;
      formData.auto_es_nuevo = this.newCar;
      if (this.documento) {
        formData.documentos.push({
          ...this.documento,
          tipo: this.tipo_documento.codigo,
          tipo_descripcion: this.tipo_documento.descripcion,
        });
      }
      if (this.comprobante_gps) {
        formData.documentos.push({
          ...this.comprobante_gps,
          tipo: "GPS",
          tipo_descripcion: "Comprobante GPS",
        });
      }
      // TODO:  debo llenar el formData
      if (isValid) {
        this.updateRequirements({
          numero_propuesta: this.proposalNumber,
          form: formData,
        })
          .then(() => {
            this.$emit("next");
          })
          .catch((message) => {
            this.$store.commit("setSnackbar", {
              active: true,
              text: message,
              top: true,
              right: true,
              color: "error",
            });
          })
          .finally(() => (this.loading = false));
        // this.$emit("next");
      }
    },
  },
  watch: {
    oldDate(newVal, oldVal) {
      if (!newVal && oldVal) {
        this.resetAditionalForm();
      }
    },
  },
};
</script>

<style>
:deep(.warning-text) {
  color: #ffcc00 !important;
}

.typeCard {
  min-width: 225px;
}
/* .selected .v-card__text {
    background-color: rgba(0, 119, 200, 0.12) !important;
  } */
</style>
