<template>
  <v-container>
    <v-row>
      <v-col cols="8" offset="2">
        <v-card class="light px-10 py-5" outlined>
          <div class="card-content">
            <v-card-text>
              <div class="d-flex align-center mb-6">
                <v-img
                  max-height="30px"
                  max-width="30px"
                  id="BackgroundSvgs"
                  class="flex-item"
                  src="@/assets/Vector.png"
                />
                <p class="titleMpay ml-2 mt-1">Mpay</p>
              </div>

              <v-alert
                :type="
                  error.value || rejected
                    ? 'error'
                    : succeed
                    ? 'success'
                    : 'info'
                "
                dismissible
                colored-border
                border="left"
                elevation="2"
              >
                <span v-if="error.value">
                  {{ error.text }}
                </span>
                <span v-else-if="succeed">
                  Se ha <b>completado</b> la operación correctamente, puede
                  proceder a descargar el documento de póliza y finalizar esta
                  propuesta.
                </span>
                <span v-else-if="rejected">
                  La operación ha sido <b>rechazada</b>, no se ha logrado
                  continuar con el medio de pago MPay 2 para esta propuesta.
                </span>
                <span v-else>
                  Se ha <b>enviado</b> un enlace al correo electrónico
                  <b>{{ correo }}</b> para continuar con la operación de
                  mpay.</span
                >
              </v-alert>

              <div class="text-center my-6">
                <v-icon size="85" v-if="error.value" color="error"
                  >mdi-alert-circle-outline</v-icon
                >
                <v-icon size="85" v-else-if="rejected" color="error"
                  >mdi-close-network-outline</v-icon
                >
                <v-icon size="85" v-else-if="succeed" color="success"
                  >mdi-check-circle-outline</v-icon
                >
                <Loading v-else />
                <!-- <v-progress-circular
                  v-else
                  :indeterminate="!cancelLoading"
                  :color="cancelLoading ? 'grey' : 'primary'"
                  size="85"
                ></v-progress-circular> -->
              </div>

              <!-- MESAJES ADICIONALES -->
              <v-list v-if="rejected" three-line>
                <v-list-item
                  v-for="(item, index) in payResponse?.lista_mensaje ?? []"
                  :key="index"
                >
                  <v-list-item-content :key="index">
                    <v-list-item-title
                      >Mensaje {{ index + 1 }}:</v-list-item-title
                    >
                    <p>{{ item.mensaje }}</p>
                  </v-list-item-content>
                </v-list-item>
              </v-list>

              <p v-else-if="!succeed && !error.value">
                En este momento el cliente se encuentra realizando el proceso de
                activación para el pago con Mpay. Le avisaremos una vez que haya
                terminado.
              </p>

              <!-- OPCIONES -->
              <!-- <h4 class="subtitle-1 mt-3 mb-0">Opciones:</h4>
              <v-divider class="my-2"></v-divider>
              <v-row>
                <v-col md="6" cols="12">
                  <v-btn height="59px" disabled block
                    ><v-icon left color="primary">mdi-cellphone</v-icon>Reenviar
                    link de acceso por SMS</v-btn
                  >
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn height="59px" disabled block
                    ><v-icon left color="primary">mdi-email-outline</v-icon
                    >Reenviar link de acceso al correo</v-btn
                  >
                </v-col>
              </v-row> -->
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- BOTON DE VOLVER -->
              <!-- Se muestra si se encuentra RECHAZADO o con ERROR (y no ha sido aprobado) -->
              <v-btn
                color="primary"
                class="mr-2 text-none"
                width="140"
                rounded
                outlined
                v-if="(rejected || error.value) && !succeed"
                @click="goBack()"
                >Volver</v-btn
              >
              <!-- BOTON DESCARGAR -->
              <!-- Se muestra si ha sido ACEPTADO -->
              <!-- <v-btn
                v-else-if="succeed"
                color="success"
                rounded
                outlined
                @click="openResponseFile()"
                width="140"
                class="mr-2 text-none"
                name="boton_descargar"
                >Descargar <v-icon right>mdi-download</v-icon></v-btn
              > -->
              <!-- BOTON DE VOLVER -->
              <!-- Se muestra si lo anterior no cumple (Por lo general estado PENDIENTE) -->
              <v-btn
                v-else
                color="error"
                rounded
                outlined
                :loading="cancelLoading"
                :disabled="cancelDisabled"
                @click="cancel"
                width="140"
                class="mr-2 text-none"
                name="boton_cancelar"
                >Cancelar</v-btn
              >
              <!-- BOTON FINALIZAR -->
              <!-- Se habilita en estado ACEPTADO -->
              <!-- <v-btn
                color="primary"
                rounded
                outlined
                :disabled="!succeed"
                :loading="loading"
                @click="successModal = true"
                width="140"
                class="mr-2 text-none"
                name="boton_finalizar"
                >Finalizar <v-icon right>mdi-check</v-icon></v-btn
              > -->
            </v-card-actions>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <!-- <SuccessModal
      v-model="successModal"
      :propuesta="proposalNumber"
      @close="finish()"
    /> -->
    <ModalSuccess
      :open="successModal"
      @close="finish()"
      :data="tassData"
      :loading="processLoading"
      :proposalNumber="proposalNumber"
    />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import { TimerMixin } from "@/Mixins/timerMixin.js";
import { paymentStatusCodes } from "@/store/resources/payments.js";
//import SuccessModal from "@/components/Modal/Modal-Mpay-Success.vue";
import ModalSuccess from "@/components/Modal/SuccessContract/Modal-mpay-2-success.vue";
import axios from "axios";
import Loading from "@/layouts/loading.vue";

export default {
  name: "MPAY2.0",
  mixins: [TimerMixin],
  props: {
    rut: {
      type: String,
      default: "",
    },
    correo: {
      type: String,
      default: "",
    },
    proposalNumber: {
      type: [String, Number],
      require: true,
      description: "Numero de la propuesta",
    },
    error: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    //SuccessModal,
    ModalSuccess,
    Loading,
  },
  data() {
    return {
      loading: false,
      succeed: false,
      rejected: false,
      cancelLoading: false,
      cancelDisabled: true,
      payResponse: null,
      successModal: false,
      tassData: null,
      processLoading: false,
    };
  },
  created() {
    if (!this.error.value) {
      this.startTimer(4000, this.refresh);
    }
  },
  methods: {
    ...mapActions("Proposal", [
      "getMPay2Estado",
      "cancelMPay2",
      "obtenerPolizaMPay2",
    ]),
    goBack() {
      this.$emit("back", true);
    },

    finish() {
      this.$router
        .push({
          name: "UserDetail",
          params: {
            rut: this.rut,
          },
        })
        .catch(() => {
          this.$router.push({ name: "dashboard" });
        });
    },

    async refresh() {
      const payload = {
        nPropuesta: this.proposalNumber,
      };
      await this.getMPay2Estado(payload)
        .then((response) => {
          const status = response.estado;
          // Condiciones para estados aprobado, rechazado y error.
          if (
            status === paymentStatusCodes[2] ||
            status === paymentStatusCodes[3] ||
            status === paymentStatusCodes[5]
          ) {
            this.stopTimer();
            this.payResponse = response;
            if (status === paymentStatusCodes[2]) {
              this.poliza(this.proposalNumber);
              this.succeed = true;
            } else {
              this.rejected = true;
            }
          }
          this.cancelDisabled = false;
        })
        .catch((error) => {
          this.snackbar(error);
        });
    },

    async poliza(n_propuesta) {
      this.processLoading = true;
      this.successModal = true;
      await this.obtenerPolizaMPay2(n_propuesta)
        .then((r) => {
          this.tassData = r;
        })
        .catch((error) => {
          this.tassData = error?.response?.data?.data;
          this.processLoading = false;
        })
        .finally(() => {
          this.processLoading = false;
        });
    },

    async cancel() {
      const payload = {
        nPropuesta: this.proposalNumber,
      };
      this.cancelLoading = true;
      this.stopTimer();
      await this.cancelMPay2(payload)
        .then(() => {
          this.snackbar(
            "Se ha cancelado el proceso de mpay satisfactoriamente!",
            "success"
          );
          this.goBack();
        })
        .catch((error) => {
          this.snackbar(error);
        })
        .finally(() => {
          this.cancelLoading = false;
          this.goBack();
        });
    },
    openResponseFile() {
      axios.get(this.payResponse.poliza).then((response) => {
        const url = response.data.data.url;
        window.open(url, "_blank");
      });
    },

    snackbar(message = "", type = "error") {
      this.$store.commit("setSnackbar", {
        active: true,
        text: message,
        top: true,
        right: true,
        color: type,
      });
    },
  },
};
</script>
<style scoped>
#BackgroundSvgs {
  width: 30px;
  height: 30px;
}

.titleMpay {
  color: #0077c8 !important;
  font-size: 20px;
  font-weight: 600;
}
</style>
