<template>
  <div>
    <v-text-field
      :value="textModel"
      :rules="rules"
      :readonly="readonly"
      :background-color="readonly === true ? '#F2F2F2' : 'none'"
      :disabled="readonly === true ? true : false"
      :placeholder="placeholder"
      v-mask="phoneMask"
      :key="phoneMask"
      :name="name"
      @input="handlerInput"
      return-masked-value
      outlined
    ></v-text-field>
  </div>
</template>

<script>
import { phoneMask } from "@/helpers/mask.js";
import { applyMask as _mask } from "@/helpers/mask.js";
export default {
  name: "txtPhone",
  props: {
    rules: {
      type: Array,
      default: () => [],
    },
    text: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "Teléfono",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },
  /* data() {
     return {
      textModel: this.text,
    };
  }, */
  computed: {
    phoneMask: () => phoneMask,
    textModel: {
      get() {
        return this.text;
      },
      set(newText) {
        this.$emit("input", newText);
      },
    },
  },
  methods: {
    handlerInput(val) {
      this.$emit("input", val);
    },
  },
  directives: {
    mask: _mask,
  },
};
</script>

<style scoped>
@import "../../assets/CSS/TextField.css";
</style>
