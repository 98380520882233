<template>
  <v-dialog
    persistent
    :value="open"
    scrollable
    width="38%"
    attach="#main-container"
  >
    <v-card class="br-6" min-height="535px">
      <v-card-text class="pa-4 pb-6">
        <v-row no-gutters>
          <v-col
            v-if="showCloseBtn"
            class="d-flex w-100 justify-space-between align-center"
          >
            <span class="h5 semiBold"></span>
            <v-btn
              v-if="
                !(infoMPAY?.estado === 'PENDIENTE') ||
                !(data?.estado === 'PENDIENTE')
              "
              @click="$emit('close')"
              icon
            >
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <div
              class="d-flex flex-column align-center"
              v-if="infoMPAY?.estado === 'GENERADA' && !loading"
            >
              <v-icon x-large>mdi-check-circle</v-icon>
              <Alert variant="success" class="w-100 mt-2">
                <template slot="content">
                  <div>
                    <span class="p">Póliza generada con éxito </span>
                  </div>
                </template>
              </Alert>

              <p>
                REF: <b>{{ infoMPAY?.poliza ?? "" }}</b>
              </p>
            </div>

            <div
              v-else-if="
                infoMPAY?.estado === 'PENDIENTE' || data?.estado === 'PENDIENTE'
              "
              class="d-flex flex-column align-center mt-2"
            >
              <v-progress-circular
                :rotate="-60"
                :size="80"
                :width="10"
                indeterminate
                class="primary--text"
              >
              </v-progress-circular>
              <span class="p mt-2"> Estamos generando su póliza</span>
            </div>

            <div v-else-if="infoMPAY?.estado === 'ERROR' || data?.estado === 'ERROR'"
               class="d-flex flex-column align-center mt-2">
              <v-icon x-large color="warning">mdi-alert-outline</v-icon>
              <Alert variant="warning" class="w-100 mt-2">
                <template slot="content">
                  <div>
                    <span class="p">
                      La operación ha sido registrada con éxito. Sin embargo,
                      tuvimos un problema en la generación del documento que
                      respalda la póliza. <br /><br />
                      Seguiremos trabajando en su emisión y le
                      <span class="semiBold"
                        >enviaremos la póliza al correo electrónico del
                        cliente</span
                      >
                      a la brevedad.
                    </span>
                  </div>
                </template>
              </Alert>
            </div>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col>
            <v-card class="light w-100" elevation="0">
              <v-card-text>
                <v-alert
                  type="error"
                  v-if="
                    infoMPAY?.estado === 'ERROR' || data?.estado === 'ERROR'
                  "
                  outlined
                  dense
                >
                  {{
                    data?.lista_mensaje[0].mensaje
                      ? data?.lista_mensaje[0].mensaje
                      : infoMPAY?.lista_mensaje[0]?.mensaje ?? ""
                  }}
                </v-alert>

                <ul
                  v-if="
                    infoMPAY?.estado === 'PENDIENTE' ||
                    data?.estado === 'PENDIENTE'
                  "
                >
                  <li>El envío del token se encuentra en progreso.</li>
                  <li>Una vez verificado el token, la poliza será generada.</li>
                  <li>
                    Podrá ver la información de su gestión una vez finalizado el
                    proceso.
                  </li>
                </ul>
                <ul
                  v-else-if="
                    infoMPAY?.estado === 'ERROR' || data?.estado === 'ERROR'
                  "
                >
                  <li>
                    Revisa en el historial de tus gestiones para confirmar que
                    todo quedo bien.
                  </li>
                  <li>
                    Si no queda regularizado en los próximos minutos contacta al
                    equipo de soporte escribiendo al:
                    <span class="bold">soporte.plataforma@mesos.cl</span> o
                    llamando al <span class="bold">227564013.</span>
                  </li>
                </ul>
                <ul v-else-if="infoMPAY?.estado === 'GENERADA' || data?.estado === 'GENERADA'">
                  <li>
                    Podrás ver la póliza al ingresar el rut del Cliente en el
                    Inicio
                  </li>
                  <li>Refuerza con el Cliente los canales de atención</li>
                  <li>
                    Recuérdale al Cliente estar atento a las comunicaciones que
                    le enviaremos para estar siempre informado del estado de su
                    seguro
                  </li>
                  <li>
                    Recuerda revisar y adjuntar todos los documentos pendientes
                  </li>
                </ul>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <template
          v-if="
            !(infoMPAY?.estado === 'PENDIENTE') ||
            !(data?.estado === 'PENDIENTE')
          "
        >
          <v-row v-if="infoMPAY?.estado === 'GENERADA'">
            <v-col>
              <div class="d-flex justify-space-around">
                <v-btn
                  color="primary"
                  rounded
                  class="text-none px-6"
                  :disabled="!infoMPAY?.urlPoliza"
                  @click="handlerDownloadDocument()"
                  >Descargar póliza</v-btn
                >
                <v-btn
                  v-if="showBtnEntendido"
                  @click="$emit('close')"
                  rounded
                  color="primary"
                  class="text-none px-6"
                >
                  Entendido
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row
            v-else-if="infoMPAY?.estado === 'ERROR' || data?.estado === 'ERROR'"
          >
            <v-col>
              <div class="d-flex justify-center">
                <v-btn
                  v-if="showBtnEntendido"
                  @click="$emit('close')"
                  rounded
                  color="primary"
                  class="text-none"
                  width="170"
                >
                  Entendido
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </template>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";
import Alert from "@/components/Alert/Alert.vue";
import axios from "axios";
import { TimerMixin } from "@/Mixins/timerMixin.js";

export default {
  name: "ModalMpay2Success",
  mixins: [TimerMixin],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    proposalNumber: {
      type: [String, Number],
      require: true,
      description: "Numero de la propuesta",
    },
  },
  data() {
    return {
      polizaSuccess: false,
      polizaError: false,
      infoMPAY: this.data,
    };
  },
  components: {
    Alert,
  },
  methods: {
    ...mapActions("Proposal", ["obtenerPolizaMPay2"]),
    handlerDownloadDocument() {
      axios({
        url: this.infoMPAY?.urlPoliza,
        method: "GET",
      })
        .then((res) => {
          const url = res?.data?.data?.url;
          window.open(url);
        })
        .catch((err) => {
          this.error = err.response.data.data.lista_mensaje[0].mensaje;
        });
    },

    async poliza(n_propuesta) {
      await this.obtenerPolizaMPay2(n_propuesta)
        .then((r) => {
          if (r.estado === "GENERADA" || r.estado === "ERROR") {
            this.stopTimer();
            this.infoMPAY = r;
          }
        })
        .catch((error) => {
          this.data = error?.response?.data?.data;
          this.stopTimer();
        });
    },
  },
  watch: {
    open: {
      handler(value) {
        if (value) {
          this.startTimer(4000, async () => {
            await this.poliza(this.proposalNumber);
          });
        }
      },
      immediate: true,
    },
  },
  computed: {
    userChannelInfo() {
      return this.$store.getters.getGeneralInfo;
    },

    accesoPantallas() {
      return this.userChannelInfo?.personalizacion_corporativa
        ?.acceso_pantallas;
    },

    showBtnEntendido() {
      return (
        this.accesoPantallas?.find(
          (v) => v.seccion === "FIRMA_DIGITAL" && v.codigo === "BOTON_ENTENDIDO"
        )?.activo ?? true
      );
    },

    showCloseBtn() {
      return (
        this.accesoPantallas?.find(
          (v) =>
            v.seccion === "PROCESO_FIRMA" && v.codigo === "BOTON_CERRAR_MODAL"
        )?.activo ?? true
      );
    },
  },
};
</script>
<style scoped>
@import "../../../assets/CSS/ServiceStyle.css";
</style>
