export const genreList = [
  { text: "Masculino", value: "M" },
  { text: "Femenino", value: "F" },
  //{ text: "Otro", value: "O" },
];

export const monthList = [
  { text: "Enero", value: 1 },
  { text: "Febrero", value: 2 },
  { text: "Marzo", value: 3 },
  { text: "Abril", value: 4 },
  { text: "Mayo", value: 5 },
  { text: "Junio", value: 6 },
  { text: "Julio", value: 7 },
  { text: "Agosto", value: 8 },
  { text: "Septiembre", value: 9 },
  { text: "Octubre", value: 10 },
  { text: "Noviembre", value: 11 },
  { text: "Diciembre", value: 12 },
];

export default {
  genreList,
  monthList,
};
